// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-destinations-index-js": () => import("./../src/pages/destinations/index.js" /* webpackChunkName: "component---src-pages-destinations-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listings-index-js": () => import("./../src/pages/listings/index.js" /* webpackChunkName: "component---src-pages-listings-index-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-slider-js": () => import("./../src/pages/slider.js" /* webpackChunkName: "component---src-pages-slider-js" */),
  "component---src-pages-themes-index-js": () => import("./../src/pages/themes/index.js" /* webpackChunkName: "component---src-pages-themes-index-js" */),
  "component---src-templates-destination-js": () => import("./../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-listings-js": () => import("./../src/templates/listings.js" /* webpackChunkName: "component---src-templates-listings-js" */),
  "component---src-templates-region-js": () => import("./../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-themes-js": () => import("./../src/templates/themes.js" /* webpackChunkName: "component---src-templates-themes-js" */)
}

